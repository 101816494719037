




































































































































































































































































































import { StakingViewModel } from "../viewmodels/staking-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref } from "vue-property-decorator";
import { walletStore } from "@/stores/wallet-store";
import { blockchainHandler } from "@/blockchain/index";

@Observer
@Component({
  components: {
    // TierCarousel: () => import("../components/tier-carousel.vue"),
    StakingDialog: () => import("../dialogs/staking-dialog.vue"),
    IncreaseStakeDialog: () => import("../dialogs/increase-stake-dialog.vue"),
    UnstakeDialog: () => import("../dialogs/unstake-dialog.vue"),
    HarvestDialog: () => import("../dialogs/harvest-dialog.vue"),
    StakeForm: () => import("../components/stake-form.vue"),
    StandardTicketForm: () =>
      import("@/modules/staking/components/staking/standard-ticket-form.vue"),
    PremiumTicketForm: () =>
      import("@/modules/staking/components/staking/premium-ticket-form.vue"),
  },
})
export default class Staking extends Vue {
  @Provide() vm = new StakingViewModel();
  @Ref("standardForm") stardardForm: any;
  @Ref("premiumForm") premiumForm: any;

  currentTicket = 0;
  isAnimating = false;
  walletStore = walletStore;
  screenWidth = window.innerWidth;

  destroyed() {
    this.vm.destroy();
  }
  async nextTicket() {
    this.currentTicket += 1;
    if (this.currentTicket > 1) this.currentTicket = 0;
    await this.animating();
  }
  async prevTicket() {
    this.currentTicket -= 1;
    if (this.currentTicket < 0) this.currentTicket = 1;
    await this.animating();
  }
  async animating() {
    this.isAnimating = true;
    await this.sleep(400);
    this.isAnimating = false;
  }
  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  requestStakeLP() {
    if (this.stardardForm.validate() && this.premiumForm.validate()) {
      this.vm.requestStakeLP();
    }
  }
}
