import { localdata } from "@/helpers/local-data";
import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper } from "@/helpers/bignumber-helper";
import { StakingHandler } from "@/helpers/staking-handler";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { priceHelper } from "../../../helpers/pancakePriceHelper";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { appProvider } from "@/app-providers";
import { NftModel } from "@/models/nft-model";
import { shuffle } from "lodash";

export class StakingViewModel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable isDialogLoading = false;
  @observable isFirstLoad = false;
  @observable tokenPrice = FixedNumber.from("0");

  @observable stakeDialog = false;
  @observable increaseStakeDialog = false;
  @observable unstakeDialog = false;

  @observable annualPercentageRate = FixedNumber.from("0");
  @observable rewardAmount = FixedNumber.from("0");
  @observable standardTicketNo = FixedNumber.from("0");
  @observable premiumTicketNo = FixedNumber.from("0");
  @observable standardHistoryTicketNo = FixedNumber.from("0");
  @observable premiumHistoryTicketNo = FixedNumber.from("0");

  @observable standardTicketInputNo = "0";
  @observable premiumTicketInputNo = "0";

  @observable approved = false;
  @observable approving = false;
  @observable lotteryApproved = false;
  @observable lotteryApproving = false;

  @observable lockDuration = moment.duration(0, "second"); // in seconds

  @observable stakedLP = FixedNumber.from("0");
  @observable userLPBalance = FixedNumber.from("0");
  @observable lastStakeTime: moment.Moment | null = null;
  @observable totalLockedAmount = FixedNumber.from("0");
  @observable totalValueLocked = FixedNumber.from("0");

  @observable usedTicket = FixedNumber.from("0");

  stakingHandler?: StakingHandler;

  @observable isCheckStandard = false;
  @observable isCheckPremium = false;

  // @observable totalStandardAmount = FixedNumber.from("0");
  // @observable totalPremiumAmount = FixedNumber.from("0");

  // summon
  @observable summonDialog = false;
  @observable isSummonDialogLoading = false;
  @observable isPremiumSummon = false;
  @observable summonedNFTs: Array<NftModel> = [];
  @observable dataLoading = false;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.stakingHandler?.injectMetamask(walletStore.web3!);
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  async loadData() {
    const stakingHandler = new StakingHandler();
    this.stakingHandler = stakingHandler;
    this.dataLoading = true;
    priceHelper.getTokenPrice().then((p) => {
      runInAction(() => (this.tokenPrice = priceHelper.tokenPriceBUSD));
    });
    try {
      await stakingHandler.load();

      runInAction(() => {
        this.totalLockedAmount = stakingHandler.lockedAmount || FixedNumber.from("0");
        this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
      });
    } catch (error) {
      console.error("loadData", error);
    }

    this.lockDuration = stakingHandler.lockDuration!;
    this.usedTicket = stakingHandler.usedTicket!;
    timer(0, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        const { lockedAmount } = await stakingHandler.getTotalLockedAmount();

        runInAction(() => {
          this.totalLockedAmount = lockedAmount;
          this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));
        });
      });

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          //TODO: Change to mainnet
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            stakingHandler.injectMetamask(walletStore.web3!);
            stakingHandler
              .approved(walletStore.account)
              .then((approved) => runInAction(() => (this.approved = approved)));

            stakingHandler
              .approvedLottery(walletStore.account)
              .then((approved) => runInAction(() => (this.lotteryApproved = approved)));

            timer(0, 5000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo();
              });
          }
        }
      )
    );
    this.dataLoading = false;
  }

  @asyncAction *premiumSpin() {
    this.isSummonDialogLoading = true;
    try {
      yield this.stakingHandler!.premiumSpin(walletStore.account);
      const detail = yield this.stakingHandler?.getLotteryDetail();
      this.usedTicket = FixedNumber.from((detail as any).usedTicket);
      // get info monster
      let nfts = yield appProvider.api.getNFTs({
        // eslint-disable-next-line
        ID: this.stakingHandler?.summonedIds,
      });
      nfts = shuffle(nfts);
      this.summonedNFTs = nfts;
      const newMonsters = localdata.getNewMonsters();
      localdata.setNewMonsters([...this.summonedNFTs, ...newMonsters]);
      return {
        result: true,
        message: "You have summoned 5 monsters successfully!",
      };
    } catch (error) {
      console.log("error: ", error);
      return {
        result: false,
        message: error.message.includes("Zero") ? "You do not have any premium ticket!" : error.message,
      };
    } finally {
      this.isSummonDialogLoading = false;
    }
  }
  @asyncAction *standardSpin() {
    this.isSummonDialogLoading = true;
    try {
      yield this.stakingHandler?.standardSpin(walletStore.account);
      const detail = yield this.stakingHandler?.getLotteryDetail();
      this.usedTicket = FixedNumber.from((detail as any).usedTicket);
      // get info monster
      let nfts = yield appProvider.api.getNFTs({
        // eslint-disable-next-line
        ID: this.stakingHandler?.summonedIds,
      });
      nfts = shuffle(nfts);
      this.summonedNFTs = nfts;
      const newMonsters = localdata.getNewMonsters();
      localdata.setNewMonsters([...this.summonedNFTs, ...newMonsters]);
      return {
        result: true,
        message: "You have summoned 5 monsters successfully!",
      };
    } catch (error) {
      console.error("error: ", error);
      return {
        result: false,
        message: error.message.includes("Zero") ? "You do not have any standard ticket!" : error.message,
      };
    } finally {
      this.isSummonDialogLoading = true;
    }
  }

  @asyncAction *fetchPoolInfo() {
    if (!walletStore.account || !this.stakingHandler) {
      this.stakedLP = FixedNumber.from("0");
    } else {
      const { lockedAmount } = yield this.stakingHandler.getLockedAmount();
      this.totalLockedAmount = lockedAmount;
      priceHelper.getTokenPrice().then((p) => {
        runInAction(() => (this.tokenPrice = priceHelper.tokenPriceBUSD));
      });
      this.totalValueLocked = this.totalLockedAmount.mulUnsafe(FixedNumber.from(this.tokenPrice));

      const [
        {
          amount,
          lastStakeTime,
          standardTicketNo,
          premiumTicketNo,
          standardHistoryTicketNo,
          premiumHistoryTicketNo,
        },
        userLPBalance,
      ] = yield Promise.all([
        this.stakingHandler.getUserInfo(walletStore.account),
        this.stakingHandler.getUserLPBalance(walletStore.account),
      ]);
      this.standardTicketNo = standardTicketNo;
      this.premiumTicketNo = premiumTicketNo;
      this.standardHistoryTicketNo = standardHistoryTicketNo;
      this.premiumHistoryTicketNo = premiumHistoryTicketNo;
      this.stakedLP = amount;
      this.lastStakeTime = lastStakeTime;
      this.userLPBalance = userLPBalance;

      if (!this.isFirstLoad) this.isFirstLoad = true;
    }
    this.dataLoading = false;
  }

  @asyncAction *approveLottery() {
    this.lotteryApproving = true;
    try {
      yield this.stakingHandler!.approveLottery(walletStore.account);
      this.lotteryApproved = true;
    } catch (error) {
      snackController.error(error.message);
    }
    this.lotteryApproving = false;
  }

  @asyncAction *approve() {
    this.approving = true;
    try {
      yield this.stakingHandler!.approve(walletStore.account);
      this.approved = true;
    } catch (error) {
      snackController.error(error.message);
    }
    this.approving = false;
  }

  @action.bound standardSummon() {
    this.summonDialog = true;
    this.isPremiumSummon = false;
  }

  @action.bound premiumSummon() {
    this.summonDialog = true;
    this.isPremiumSummon = true;
  }

  @action.bound requestStakeLP() {
    this.stakeDialog = true;
  }

  @action.bound increaseStakeLP() {
    this.increaseStakeDialog = true;
  }

  @action.bound requestUnstakeLP() {
    this.unstakeDialog = true;
  }

  @asyncAction *confirmStake() {
    this.isDialogLoading = true;
    try {
      yield this.stakingHandler!.stakeLP(
        walletStore.account,
        this.totalTokenAmount,
        this.standardTicketInputNo,
        this.premiumTicketInputNo
      );
      snackController.success("Stake successed");
      this.fetchPoolInfo();
      this.stakeDialog = false;
      this.standardTicketInputNo = "0";
      this.premiumTicketInputNo = "0";
    } catch (err) {
      snackController.error(err.message);
      console.error(err);
    } finally {
      this.isDialogLoading = false;
    }
  }

  @asyncAction *confirmUnstake() {
    this.isDialogLoading = true;
    try {
      yield this.stakingHandler!.unstakeLP(walletStore.account);
      snackController.success("Unstake successed");
      this.fetchPoolInfo();
      this.stakeDialog = false;
    } catch (err) {
      snackController.error(err.message);
    } finally {
      this.isDialogLoading = false;
    }
  }
  @action.bound cancelSummonDialog() {
    this.summonDialog = false;
  }

  @action.bound cancelStakeDialog() {
    this.stakeDialog = false;
  }
  @action.bound cancelIncreaseStakeDialog() {
    this.increaseStakeDialog = false;
    this.standardTicketInputNo = "0";
    this.premiumTicketInputNo = "0";
  }
  @action.bound cancelUnstakeDialog() {
    this.unstakeDialog = false;
  }

  @action.bound changeStandardTicketInputNo(number) {
    if (!number) this.standardTicketInputNo = "0";
    this.standardTicketInputNo = number;
    // if (number) {
    //   this.totalStandardAmount = this.stakingHandler!.standardPrice!.mulUnsafe(
    //     FixedNumber.from(this.standardTicketInputNo)
    //   );
    // } else {
    //   this.totalStandardAmount = FixedNumber.from("0");
    // }
  }

  @action.bound changePremiumTicketInputNo(number) {
    if (!number) this.premiumTicketInputNo = "0";
    this.premiumTicketInputNo = number;
    // if (number) {
    //   this.totalPremiumAmount = this.stakingHandler!.premiumPrice!.mulUnsafe(
    //     FixedNumber.from(this.premiumTicketInputNo)
    //   );
    // } else {
    //   this.totalPremiumAmount = FixedNumber.from("0");
    // }
  }

  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from("0"));
  }

  @computed get lockInDays() {
    return this.lockDuration.asDays();
  }
  @computed get lockInSeconds() {
    return this.lockDuration.asSeconds();
  }
  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.lockDuration);
    }
    return null;
  }
  @computed get canUnstake() {
    return moment().isAfter(this.canUnstakeTime);
  }

  @computed get validDialogInputAmount() {
    if (!(this.standardTicketInputNo && this.premiumTicketInputNo)) {
      return false;
    }
    try {
      if (
        FixedNumber.from(this.standardTicketInputNo).isNegative() &&
        FixedNumber.from(this.premiumTicketInputNo).isZero()
      )
        return false;
      else if (
        FixedNumber.from(this.standardTicketInputNo).isZero() &&
        FixedNumber.from(this.premiumTicketInputNo).isNegative()
      )
        return false;
      else if (
        FixedNumber.from(this.standardTicketInputNo).isNegative() &&
        FixedNumber.from(this.premiumTicketInputNo).isNegative()
      )
        return false;
      else if (
        FixedNumber.from(this.standardTicketInputNo).isZero() &&
        FixedNumber.from(this.premiumTicketInputNo).isZero()
      )
        return false;
      return bigNumberHelper.lte(this.totalTokenAmount, this.userLPBalance);
    } catch (error) {
      return false;
    }
  }

  @computed get totalStandardAmount() {
    const standardPrice = this.stakingHandler?.standardPrice;
    const standardTicketInputNo = this.standardTicketInputNo;
    if (!standardPrice || !standardTicketInputNo) return FixedNumber.from("0");
    return this.stakingHandler?.standardPrice!.mulUnsafe(FixedNumber.from(this.standardTicketInputNo!));
  }

  @computed get totalPremiumAmount() {
    const premiumPrice = this.stakingHandler?.premiumPrice;
    const premiumTicketInputNo = this.premiumTicketInputNo;
    if (!premiumPrice || !premiumTicketInputNo) return FixedNumber.from("0");
    return this.stakingHandler?.premiumPrice!.mulUnsafe(FixedNumber.from(this.premiumTicketInputNo!));
  }

  @computed get totalStakeAmount() {
    return this.stakedLP.addUnsafe(this.totalTokenAmount);
  }

  @computed get lockedUntil() {
    return moment()
      .add(this.lockDuration)
      .format("DD/MM/YYYY");
  }

  @computed get totalTokenAmount() {
    if (!this.totalStandardAmount && !this.totalPremiumAmount) return FixedNumber.from("0");
    return this.totalStandardAmount!.addUnsafe(this.totalPremiumAmount!);
  }

  @computed get totalSummon() {
    return this.usedTicket.mulUnsafe(FixedNumber.from(5));
  }

  @computed get isConfirmSummon() {
    const isPremiumSummon = this.isPremiumSummon;
    if (isPremiumSummon) {
      return bigNumberHelper.gte(this.userLPBalance, FixedNumber.from("400"));
    } else {
      return bigNumberHelper.gte(this.userLPBalance, FixedNumber.from("200"));
    }
  }
}
