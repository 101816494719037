/* eslint-disable prettier/prettier */
import { FixedNumber } from "@ethersproject/bignumber";
import { walletStore } from "@/stores/wallet-store";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { blockchainHandler } from "@/blockchain";
import moment from "moment";
import Web3 from "web3";
import { asyncAction } from "mobx-utils";

//TODO: Change to mainnet
const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

export class StakingHandler {
  farmContract: any;
  LPTokenContract: any;
  lockDuration?: moment.Duration;
  rewardToken?: string;
  poolToken?: string;
  standardPrice?: FixedNumber;
  premiumPrice?: FixedNumber;
  lockedAmount?: FixedNumber;

  lotteryContract?: any;
  usedTicket?: FixedNumber;
  summonedIds?: Array<string>;
  isRares?: Array<boolean>;

  nftContract?: any;

  constructor() {
    this.LPTokenContract = new web3.eth.Contract(
      require("./erc20.abi.json"),
      process.env.VUE_APP_TOKEN_ADDRESS
    );
    this.farmContract = new web3.eth.Contract(
      require("./farm.abi.v2.json"),
      process.env.VUE_APP_FARM_ADDRESS
    );
    this.lotteryContract = new web3.eth.Contract(
      require("./lottery.abi.v2.json"),
      process.env.VUE_APP_LOTTERY_ADDRESS
    );
    this.nftContract = new web3.eth.Contract(require("./erc721.abi.json"), process.env.VUE_APP_NFT_ADDRESS);
  }

  @asyncAction *load() {
    loadingController.increaseRequest();
    try {
      const rewardToken = yield this.farmContract.methods.rewardToken().call();
      this.rewardToken = web3.utils.toChecksumAddress(rewardToken);
      const lockDuration = yield this.farmContract.methods.lockDuration().call();
      // const {
      //   0: poolToken,
      //   1: allocPoint,
      //   2: lastRewardBlock,
      //   3: accRewardPerShare,
      //   4: lockDuration
      // } = await this.farmContract.methods.poolInfo(0).call()
      this.poolToken = web3.utils.toChecksumAddress(rewardToken);
      const { standardPrice, premiumPrice } = yield this.getTokenPrices();

      this.standardPrice = FixedNumber.from(standardPrice);
      this.premiumPrice = FixedNumber.from(premiumPrice);
      const { usedTicket } = yield this.getLotteryDetail();
      this.usedTicket = FixedNumber.from(usedTicket);
      this.lockDuration = moment.duration(lockDuration, "seconds");

      this.LPTokenContract = new web3.eth.Contract(require("./erc20.abi.json"), this.poolToken);

      const { lockedAmount } = yield this.getLockedAmount();
      this.lockedAmount = lockedAmount;
    } catch (error) {
      console.error(error);
    } finally {
      loadingController.decreaseRequest();
    }
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.farmContract = new web3.eth.Contract(
        require("./farm.abi.v2.json"),
        process.env.VUE_APP_FARM_ADDRESS
      );
      this.LPTokenContract = new web3.eth.Contract(
        require("./erc20.abi.json"),
        process.env.VUE_APP_TOKEN_ADDRESS
      );
      this.lotteryContract = new web3.eth.Contract(
        require("./lottery.abi.v2.json"),
        process.env.VUE_APP_LOTTERY_ADDRESS
      );
      this.nftContract = new web3.eth.Contract(require("./erc721.abi.json"), process.env.VUE_APP_NFT_ADDRESS);
    }
  }

  async premiumSpin(account) {
    const f = this.lotteryContract.methods.sprin(1);
    const res = await sendRequest(f, account);
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.summonedIds as Array<string>;
    this.isRares = summonedEvent.returnValues.isRares as Array<boolean>;
  }

  async standardSpin(account) {
    const f = this.lotteryContract.methods.sprin(0);
    const res = await sendRequest(f, account);
    const summonedEvent = (res as any).events["Summoned"];
    this.summonedIds = summonedEvent.returnValues.summonedIds as Array<string>;
    this.isRares = summonedEvent.returnValues.isRares as Array<boolean>;
  }

  async getLotteryDetail() {
    const usedTicket = await this.lotteryContract.methods.usedTicket().call();
    return { usedTicket };
  }

  async approved(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_FARM_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approve(account) {
    try {
      const f = this.LPTokenContract.methods.approve(
        process.env.VUE_APP_FARM_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.log("error", error);
    }
  }

  async approvedLottery(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_LOTTERY_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approveLottery(account) {
    try {
      const f = this.LPTokenContract.methods.approve(
        process.env.VUE_APP_LOTTERY_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.error("error", error);
    }
  }

  async approvedNFT(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_NFT_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approveNFT(account) {
    try {
      const f = this.LPTokenContract.methods.approve(
        process.env.VUE_APP_NFT_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.error("error", error);
    }
  }

  async approvedTraining(account) {
    try {
      const allowance = await this.LPTokenContract.methods
        .allowance(account, process.env.VUE_APP_TRAINING_ADDRESS)
        .call();
      return !!+web3.utils.fromWei(allowance);
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async approveTraining(account) {
    try {
      const f = this.LPTokenContract.methods.approve(
        process.env.VUE_APP_TRAINING_ADDRESS,
        web3.utils.toWei(`${2 ** 64 - 1}`)
      );
      await sendRequest(f, account);
    } catch (error) {
      console.error("error", error);
    }
  }

  async getTotalLockedAmount() {
    const amount = await this.farmContract.methods.totalAmount().call();
    const lockedAmount = FixedNumber.from(`${web3.utils.fromWei(amount)}`);
    return { lockedAmount };
  }
  async stakeLP(account, amount, standardTicketNo, premiumTicketNo) {
    const f = this.farmContract.methods.deposit(web3.utils.toWei(`${amount.toString()}`), [
      +standardTicketNo.toString(),
      +premiumTicketNo.toString(),
    ]);
    await sendRequest(f, account);
  }
  async unstakeLP(account) {
    const f = this.farmContract.methods.withdraw();
    await sendRequest(f, account);
  }

  async getTokenPrices() {
    const standardPrice = await this.farmContract.methods.ticketPrices(0).call();
    const premiumPrice = await this.farmContract.methods.ticketPrices(1).call();

    return {
      standardPrice: web3.utils.fromWei(standardPrice),
      premiumPrice: web3.utils.fromWei(premiumPrice),
    };
  }

  async getUserInfo(account) {
    const { 0: amount, 1: lastStakeTime } = await this.farmContract.methods.userInfo(account).call();

    const standardTicketNo = await this.farmContract.methods.userTicketNo(account, 0).call();
    const premiumTicketNo = await this.farmContract.methods.userTicketNo(account, 1).call();

    const standardHistoryTicketNo = await this.farmContract.methods.historyUserTicketNo(account, 0).call();
    const premiumHistoryTicketNo = await this.farmContract.methods.historyUserTicketNo(account, 1).call();

    return {
      amount: FixedNumber.from(`${web3.utils.fromWei(amount)}`),
      lastStakeTime: +lastStakeTime ? moment(+lastStakeTime * 1000) : null,
      standardTicketNo: FixedNumber.from(standardTicketNo),
      premiumTicketNo: FixedNumber.from(premiumTicketNo),
      standardHistoryTicketNo: FixedNumber.from(standardHistoryTicketNo),
      premiumHistoryTicketNo: FixedNumber.from(premiumHistoryTicketNo),
    };
  }
  async getUserLPBalance(account) {
    const allowance = await this.LPTokenContract.methods.balanceOf(account).call();
    return FixedNumber.from(`${web3.utils.fromWei(allowance)}`);
  }

  async getLockedAmount() {
    const amount = await this.farmContract.methods.totalAmount().call();
    const lockedAmount = FixedNumber.from(`${web3.utils.fromWei(amount)}`);
    return { lockedAmount };
  }
}
